<template>
  <div>
    <el-form inline class="mb-20">
<!--      <el-form-item label="标题">-->
<!--        <el-input placeholder="请输入标题"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="发布者">-->
<!--        <el-input placeholder="请输入发布者"/>-->
<!--      </el-form-item>-->
      <el-form-item label="闲置分类">
        <el-select placeholder="请选择闲置分类" v-model="form.category_id">
          <el-option label="全部" value=""/>
          <template v-for="(item, index) in classfiy_list" :key="index">
            <el-option :label="item.title" :value="item.id"/>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字搜索">
        <el-input placeholder="请输入关键字搜索" v-model="form.search"/>
      </el-form-item>
      <el-form-item label="帖子状态">
        <el-select placeholder="请选择帖子状态" v-model="form.article_status">
          <el-option label="全部" value=""/>
          <el-option label="正常" :value="10"/>
          <el-option label="被举报" :value="20"/>
          <el-option label="封禁" :value="30"/>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="售价">-->
<!--        <el-input placeholder="请输入售价"/>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="article_id" label="ID"/>
      <el-table-column align="center" prop="goods_name" label="商品标题"/>
<!--      <el-table-column align="center" prop="user.nickName" label="发布者"/>-->
      <el-table-column align="center" prop="category_name" label="闲置分类"/>
      <el-table-column align="center" prop="price" label="售价"/>
      <el-table-column align="center" prop="create_time" label="发布时间"/>
      <el-table-column align="center" prop="article_status.text" label="帖子状态"/>

      <el-table-column align="center" label="操作">
        <template #default="{row}">
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :current-page="params.page"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="二手详情"
      width="700px"
      top="5vh"
    >
      <el-descriptions border :column="1">
        <el-descriptions-item label="标题">{{detail.goods_name}}</el-descriptions-item>
        <el-descriptions-item label="发布者">{{detail.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布者手机号">{{detail.phone}}</el-descriptions-item>
        <el-descriptions-item label="照片">
          <el-image
            v-for="(item, index) in detail.file"
            :key="index"
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="globalData.cos_domain + (item.thumbnail || item.url)"
            :preview-src-list="[globalData.cos_domain + (item.thumbnail || item.url)]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="闲置分类">{{detail.category_name}}</el-descriptions-item>
        <el-descriptions-item label="售价">{{detail.price}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="上架状态">{{detail.status.text}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: 'Idle',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      detail: null,
      form: {
        category_id: '',
        search: '',
        article_status: ''
      },
      classfiy_list: [],
    };
  },
  computed: {
    ...mapState(['globalData'])
  },
  created() {
    this.getList(1)
    this.$api.articleTransferCategory.list().then(res => {
      this.classfiy_list = res.data
    })
  },
  methods: {
    reset() {
      this.form = {
        category_id: '',
        search: '',
        article_status: 10
      }
      this.submit()
    },
    showDetailBox(data) {
      this.detail = data;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.articleTransfer.page({
        ...this.form,
        page: page || this.params.page,
      }).then((res) => {
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    del(data) {
      let that = this;
      this.$api.article.delete({
        article_id: data.article_id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    submit() {
      this.getList(1)
    },
    changePage(page) {
      this.getList(page)
    },
  },
};
</script>
